import { useRouter } from 'next/router';

import { BorderLink } from '@playbooks/interface/links';
import { LanguageCardActions } from 'components/language/language-card-actions';
import {
	DetailCard,
	DisplayCard,
	ListCard,
	PhotoLinkCard,
	PillCard,
	PreviewCard,
	SearchBtnCard,
	SelectCard,
} from 'molecules/cards';

export const LanguageDetailCard = ({ language, loading, tailwind }) => {
	// Render
	return (
		<DetailCard
			icon='language'
			photo={language.thumbnail}
			status={language.status}
			title={language.name}
			subtitle={language.tagline}
			text={language.description}
			footer={language.updatedAt}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const LanguageDisplayCard = ({ language, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${language.meta?.totalBounties} bounties`
			: `${language.meta?.totalRepos} repositories`;
	// Render
	return (
		<DisplayCard
			icon='brackets-curly'
			photo={language.thumbnail}
			title={language.name}
			subtitle={computedSubtitle}
			text={language.tagline}
			href={`${rootLink}/languages/${language.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</DisplayCard>
	);
};

export const LanguageListCard = ({ language, loading, rootLink, tailwind }) => {
	// Render
	return (
		<ListCard
			icon='language'
			photo={language.thumbnail}
			title={language.name}
			subtitle={language.tagline}
			href={`${rootLink}/languages/${language.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			<LanguageCardActions type='list' language={language} rootLink={rootLink} />
		</ListCard>
	);
};

export const LanguagePhotoLinkCard = ({ language, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${language.meta?.totalBounties} bounties`
			: `${language.meta?.totalRepos} repositories`;
	// Render
	return (
		<PhotoLinkCard
			icon='brackets-curly'
			photo={language.thumbnail}
			title={language.name}
			subtitle={computedSubtitle}
			href={`${rootLink}/languages/${language.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PhotoLinkCard>
	);
};

export const LanguagePillCard = ({ language, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${language.meta?.totalBounties} bounties`
			: `${language.meta?.totalRepos} repositories`;
	// Render
	return (
		<PillCard
			icon='brackets-curly'
			photo={language.thumbnail}
			title={language.name}
			subtitle={computedSubtitle}
			href={`${rootLink}/languages/${language.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PillCard>
	);
};

export const LanguagePreviewCard = ({ language, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewCard
			icon='language'
			photo={language.thumbnail}
			title={language.name}
			subtitle={language.tagline}
			loading={loading}
			tailwind={tailwind}>
			<BorderLink size='icon' icon='chevron-right' href={`${rootLink}/languages/${language.uuid}`} />
		</PreviewCard>
	);
};

export const LanguageSearchCard = ({ language, onClick, tailwind }) => {
	// Render
	return (
		<SearchBtnCard
			icon='language'
			modelName='language'
			photo={language.thumbnail}
			title={language.name}
			text={language.description}
			onClick={() => onClick(language)}
			tailwind={tailwind}
		/>
	);
};

export const LanguageSelectCard = ({ language, selected = [], loading, onClick, tailwind }) => {
	// Render
	return (
		<SelectCard
			icon='language'
			photo={language.thumbnail}
			title={language.name}
			loading={loading}
			selected={selected.map(v => v.id).includes(language.id)}
			onClick={() => onClick(language)}
			tailwind={tailwind}
		/>
	);
};
