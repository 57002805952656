import { useRouter } from 'next/router';

import { BorderLink } from '@playbooks/interface/links';
import { ToolCardActions } from 'components/tool/tool-card-actions';
import {
	DetailCard,
	DisplayCard,
	ListCard,
	PhotoLinkCard,
	PillCard,
	PreviewCard,
	SearchBtnCard,
	SelectCard,
} from 'molecules/cards';

export const ToolDetailCard = ({ tool, loading, tailwind }) => {
	// Render
	return (
		<DetailCard
			icon='brackets-curly'
			photo={tool.thumbnail}
			status={tool.status}
			title={tool.name}
			subtitle={tool.tagline}
			text={tool.description}
			footer={tool.updatedAt}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const ToolDisplayCard = ({ tool, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${tool.meta?.totalBounties} bounties`
			: `${tool.meta?.totalRepos} repositories`;
	// Render
	return (
		<DisplayCard
			icon='brackets-curly'
			photo={tool.thumbnail}
			title={tool.name}
			subtitle={computedSubtitle}
			text={tool.tagline}
			href={`${rootLink}/tools/${tool.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</DisplayCard>
	);
};

export const ToolListCard = ({ tool, loading, rootLink, tailwind }) => {
	// Render
	return (
		<ListCard
			icon='brackets-curly'
			photo={tool.thumbnail}
			title={tool.name}
			subtitle={tool.tagline}
			text={tool.description}
			href={`${rootLink}/tools/${tool.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			<ToolCardActions type='list' tool={tool} rootLink={rootLink} />
		</ListCard>
	);
};

export const ToolPhotoLinkCard = ({ tool, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${tool.meta?.totalBounties} bounties`
			: `${tool.meta?.totalRepos} repositories`;
	// Render
	return (
		<PhotoLinkCard
			icon='brackets-curly'
			photo={tool.thumbnail}
			title={tool.name}
			subtitle={computedSubtitle}
			href={`${rootLink}/tools/${tool.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PhotoLinkCard>
	);
};

export const ToolPillCard = ({ tool, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${tool.meta?.totalBounties} bounties`
			: `${tool.meta?.totalRepos} repositories`;
	// Render
	return (
		<PillCard
			icon='brackets-curly'
			photo={tool.thumbnail}
			title={tool.name}
			subtitle={computedSubtitle}
			href={`${rootLink}/tools/${tool.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PillCard>
	);
};

export const ToolPreviewCard = ({ tool, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewCard
			icon='brackets-curly'
			photo={tool.thumbnail}
			title={tool.name}
			subtitle={tool.tagline}
			loading={loading}
			tailwind={tailwind}>
			<BorderLink size='icon' icon='chevron-right' href={`${rootLink}/tools/${tool.uuid}`} />
		</PreviewCard>
	);
};

export const ToolSearchCard = ({ tool, onClick, tailwind }) => {
	// Render
	return (
		<SearchBtnCard
			icon='brackets-curly'
			modelName='tools'
			photo={tool.thumbnail}
			title={tool.name}
			text={tool.description}
			onClick={() => onClick(tool)}
			tailwind={tailwind}
		/>
	);
};

export const ToolSelectCard = ({ tool, selected = [], onClick, loading, tailwind }) => {
	// Render
	return (
		<SelectCard
			icon='brackets-curly'
			photo={tool.thumbnail}
			title={tool.name}
			loading={loading}
			selected={selected.map(v => v.id).includes(tool.id)}
			onClick={() => onClick(tool)}
			tailwind={tailwind}
		/>
	);
};
